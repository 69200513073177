#articles-wrap{
  .articles-table-wrapper{
    margin: 20px;
    .table-tools-header{
      margin-bottom: 20px;
      .table-header{
        margin-top: 0px;
        line-height: 40px;
        height: 40px;
      }
    }
  }
}

#create-news-wrap{
  .create-news-header-txt{
    margin: 20px;
  }
  .el-form{
    width: 80%;
  }
}

.min-height-300{
  min-height: 300px;
}

#introEditorWrapper{
  min-height: 250px;
}
