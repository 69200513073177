#media-manager-wrap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background-color: rgba(0,0,0,0.9);
  z-index: 100;
  .title{
    display: block;
    height: 60px;
    color: #fff;
    padding: 0px 20px;
    border: solid 1px #fff;
    .text{
      line-height: 60px;
      font-size: 30px;
      float: left;
    }
    .close-btn{
      float: right;
      font-weight: 100;
      cursor: pointer;
      &:hover{
        color: red;
      }
    }
  }
  .explorer-wrap{
    width: 900px;
    min-height: 661px;
    margin: 20px auto;;
    background-color: #fff;
    .tool-bar{
      display: block;
      width: 100%;
      clear: both;
      border-bottom: solid 1px #ccc;
      .selected-path-wrap{
        float: left;
        width: 400px;
        height: 60px;
        line-height: 29px;
        margin: 0;
        padding-top: 13px;
        .select-target{
          color: red;
          width: 300px;
          outline: none;
        }
        .cp-to-select-btn{
          border-radius: 0px;
          margin-left: -5px;
          background-color: #20a0ff;
          border: none;
          outline: none;
          color: #fff;
        }
      }
      .progress-bar-wrap{
        padding-top:10px;
        ul{
          li{
            font-size: 14px;
            margin-bottom: 5px;
            &.error{
              color: #ff0000;
            }
          }
        }
      }
      .create-folder-wrap{
        padding: 10px;
        .folder-name-input{
          width: 400px;
          padding: 8px;
          outline: none;
        }
        .create-folder-btn{
          margin-left: -4px;
          height: 41px;
        }
      }
    }
    .folders-tree-wrap{
      display: block;
      float: left;
      width: 100%;
      height: 590px;
      padding-bottom: 10px;
      .root-folder-name{
        font-weight: bold;
        padding-left: 20px;
        font-size: 18px;
        color: #333;
      }
      .el-menu{
        background-color: #fff;
        .el-menu-item{
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .files-list-wrap{
      display: block;
      width: 100%;
      height: 600px;
      padding: 0px 10px 10px 10px;
      border-left: solid 1px #ccc;
      .no-left-padding{
        padding-left: 0px;
      }
      .un-style{
        list-style: none;
        .folder-card{
          height: 30px;
          line-height: 30px;
          cursor: pointer;
        }
        .file-card{
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          .txt-red{
            color: #ff0000;
          }
          &:hover{
            color: #ff0000;
          }
        }
      }
    }
    .image-previewer-wrap{
      .preview{
        padding: 5px;
        width: 200px;
        img{
          width: 180px;
        }
      }
      .open-btn{
        display: block;
        margin-top: 20px;
      }
    }
  }
}
.media-manager-trigger{
  button{
    width: 100%;
    height: 36px;
    line-height: 36px;
    background-color: #20a0ff;
    border: none;
    outline: none;
    color: #fff;
    margin-left: -2px;
  }
}

.file-uploader-wrap{
  position: fixed;
  top: -100em;
}
